export const MEAL_OPTIONS = [
  { text: 'Non-Vegetarian', value: 'Non-Vegetarian' },
  { text: 'Vegetarian', value: 'Vegetarian' }
]

export const SHIRT_SIZE = [
  { text: 'Free Size', value: 'Free Size' },
  { text: 'Short Sleeve : XS', value: 'Short Sleeve : XS' },
  { text: 'Short Sleeve : S', value: 'Short Sleeve : S' },
  { text: 'Short Sleeve : M', value: 'Short Sleeve : M' },
  { text: 'Short Sleeve : L', value: 'Short Sleeve : L' },
  { text: 'Short Sleeve : XL', value: 'Short Sleeve : XL' },
  { text: 'Short Sleeve : XXL', value: 'Short Sleeve : XXL' },
  { text: 'Short Sleeve : 3XL', value: 'Short Sleeve : 3XL' },
  { text: 'Short Sleeve : 4XL', value: 'Short Sleeve : 4XL' },
  { text: 'Short Sleeve : 5XL', value: 'Short Sleeve : 5XL' },
  { text: 'Short Sleeve : 6XL', value: 'Short Sleeve : 6XL' },
  { text: 'Long Sleeve : XS', value: 'Long Sleeve : XS' },
  { text: 'Long Sleeve : S', value: 'Long Sleeve : S' },
  { text: 'Long Sleeve : M', value: 'Long Sleeve : M' },
  { text: 'Long Sleeve : L', value: 'Long Sleeve : L' },
  { text: 'Long Sleeve : XL', value: 'Long Sleeve : XL' },
  { text: 'Long Sleeve : XXL', value: 'Long Sleeve : XXL' },
  { text: 'Long Sleeve : 3XL', value: 'Long Sleeve : 3XL' },
  { text: 'Long Sleeve : 4XL', value: 'Long Sleeve : 4XL' },
  { text: 'Long Sleeve : 5XL', value: 'Long Sleeve : 5XL' },
  { text: 'Long Sleeve : 6XL', value: 'Long Sleeve : 6XL' }
]

export const GENDER = [
  { text: 'Male', value: 'Male' },
  { text: 'Female', value: 'Female' }
]

export const ACCOMODATION = [
  { text: 'Yes', value: 'Yes' },
  { text: 'No', value: 'No' }
]

export const CLAIMS = [
  { text: 'Claim', value: 'Claim' },
  { text: 'Operation', value: 'Operation' },
  { text: 'Custom Service', value: 'Custom Service' },
  { text: 'Others', value: 'Others' }
]

export const MED_PLAN = [
  { text: 'PMM', value: 'PMM' },
  { text: 'PMM2', value: 'PMM2' },
  { text: 'Pmm3', value: 'Pmm3' },
  { text: 'PMM4', value: 'PMM4' },
  { text: 'Pmm5', value: 'Pmm5' },
  { text: 'PruHealth', value: 'PruHealth' },
  { text: 'PruFleximed', value: 'PruFleximed' },
  { text: 'PRUValue med', value: 'PRUValue med' },
  { text: 'PruMillionmed', value: 'PruMillionmed' },
  { text: 'Others', value: 'Others' }
]
